import React from "react";
import "./RecentlyVisited.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import recentlyViewedImg from "../../assets/images/recentlyVieweddiscussion.jpg";
import webinarHost1 from "../../assets/images/webinarHost1.jpg";
import { useNavigate } from "react-router-dom";

const RecentlyVisited = () => {
  const BurningTopics = [
    {
      topic: "What Are the Best Strategies for Networking at Industry Events?",
      name: "Posted by Alex Carl",
    },
    {
      topic: "How to Negotiate Salary After Receiving a Job Offer?",
      name: "Posted by Mitchell Marsh",
    },
    {
      topic: "Tips for Acing Virtual Job Interviews",
      name: "Posted by Ricky Ponting",
    },
  ];

  const RelatedTopics = [
    {
      topic: "What Are the Best Strategies for Networking at Industry Events?",
    },
    {
      topic: "How to Negotiate Salary After Receiving a Job Offer?",
    },
    {
      topic: "Tips for Acing Virtual Job Interviews",
    },
  ];

  const navigate=useNavigate();

  const handleCreatePost = () => {
    navigate('/createDiscussion')
  };

  return (
    <div className="recently-visit-container">
      <div>
        <CustomButton
          label="+  Create Post"
          onClick={handleCreatePost}
          type="submit create-post-button"
        />
      </div>
      <div className="host-container">
        <div className="host-img-container">
          <img className="webinar-host-img" src={webinarHost1} alt="host" />
        </div>
        <div className="host-details">
          <div>Mitchell Johnson</div>
          <div>Human Resource Manager</div>
        </div>
      </div>
      <div>
        <div className="topic-heading">Related Topics</div>
        {RelatedTopics.map((data) => (
          <div className="related-topics-container">{data.topic}</div>
        ))}
      </div>

      <div className="recently-visit-container-first-div">
        <div className="topic-heading">Recently Visited</div>
        <div className="recently-visited-img-container">
          <img className="recently-visited-img" src={recentlyViewedImg} />
        </div>
        <div className="topic-des-container">
          <div>
            What Are the Best Strategies for Networking at Industry Events?
          </div>
          <div>
            <i class="fa-solid fa-arrow-right"></i>
          </div>
        </div>
        <div className="recently-visited-postedby">Posted by Alex Carl</div>
        <div className="upvote-comments-container">
          <CustomButton
            label="24 Upvotes"
            onClick={() => console.log("Button clicked!")}
            type="backto-button upvote-comments-button"
          />

          <CustomButton
            label="21 Comments"
            onClick={() => console.log("Button clicked!")}
            type="backto-button upvote-comments-button"
          />
        </div>
      </div>
      <div className="topic-heading">Burning Topics</div>
      <div>
        {BurningTopics.map((data) => (
          <div className="burning-topic-container">
            <div className="topic-des-container">
              <div>{data.topic}</div>
              <div>
                <i class="fa-solid fa-arrow-right"></i>
              </div>
            </div>
            <div className="recently-visited-postedby">{data.name}</div>
            <div className="upvote-comments-container">
              <CustomButton
                label="24 Upvotes"
                onClick={() => console.log("Button clicked!")}
                type="backto-button upvote-comments-button"
              />

              <CustomButton
                label="21 Comments"
                onClick={() => console.log("Button clicked!")}
                type="backto-button upvote-comments-button"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentlyVisited;
