import React from "react";
import DiscussionForumFilter from "../../containers/DiscussionForum/DiscussionForumFilter";
import RecentlyVisited from "../../containers/DiscussionForum/RecentlyVisited";
import DiscussionPost from "../../containers/DiscussionForum/DiscussionPost";

const DiscussionPostPage = () => {
  return (
    <>
      <div className="main-home-container">
        <div className="main-discussion-form-container">
          <DiscussionForumFilter />
          <DiscussionPost />
          <RecentlyVisited />
        </div>
      </div>
    </>
  );
};

export default DiscussionPostPage;
