import React, { useState } from "react";
import "./DashboardLearningHub.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../components/CustomTable/CustomTable";

const DashboardLearningHub = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");

  const tabs = [
    { name: "All", count: 84 },
    { name: "Article", count: 39 },
    { name: "Webinar", count: 33 },
    { name: "Published", count: 33 },
    { name: "Draft", count: 51 },
    { name: "Restricted", count: 21 },
  ];

  const columns = [
    { key: "contentId", label: "Content ID" },
    { key: "title", label: "Title" },
    { key: "category", label: "Category", type: "category", sortable: true },
    { key: "author", label: "Author" },
    { key: "datePublished", label: "Date Published" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const data = [
    {
      contentId: "CD001",
      title: "Mastering Job Interviews: Tips from Top Re...",
      category: "Article",
      author: "Mitchell Johnson",
      datePublished: "12 Mar 2022",
      status: "Published",
    },
    {
      contentId: "CD002",
      title: "Building a Strong Professional Network Am...",
      category: "Webinar",
      author: "Brett Lee",
      datePublished: "08 Apr 2022",
      status: "Draft",
    },
    {
      contentId: "CD003",
      title: "5 Ways to Enhance Your LinkedIn Profile to...",
      category: "Article",
      author: "Ricky Ponting",
      datePublished: "23 Mar 2022",
      status: "Restricted",
    },
    {
      contentId: "CD004",
      title: "Managing Work-Life Balance in a Busy Worl...",
      category: "Article",
      author: "Mitchell Johnson",
      datePublished: "09 May 2023",
      status: "Published",
    },
    {
      contentId: "CD005",
      title: "LinkedIn Profile Mastery",
      category: "Webinar",
      author: "Glenn Maxwell",
      datePublished: "30 Aug 2023",
      status: "Draft",
    },
  ];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
      // { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Learning Hub",
    des: "Oversee content and user progress to ensure a seamless and effective learning experience.",
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      {/* <DashboardHeading  heading={headingDes.heading} des={headingDes.des}/> */}
      <div className="setting-page-container">
        {/* <DashboardSubNavbar setNavClick={setNavClick}/> */}
        <div className="sub-setting-page-container blog-post-table-container">
          {/* {navClick=="account"? <AccountSetting/>:<NotificationSetting/>} */}
          <div className="dashboard-table-heading">
            <h4>Overview</h4>
          </div>
          {/* <DashboardTable
            tableData={tableData}
            tableHeading={tableHeading}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          /> */}
          <CustomTable
            columns={columns}
            data={data}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardLearningHub;
