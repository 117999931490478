import React from "react";
import "./Header.css";
import headerImage from "../../assets/images/landing-content-img-right.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const Header = () => {
  return (
    <div className="main-landing-header-container">
      <div className="landing-header-container">
        <div className="landing-header-detail-container">
          <div>
            <h1>Stand Out to</h1>
            <h1>
              <span className="heading-different-color">Your Recruiter</span>{" "}
              with Internal{" "}
              <span className="heading-different-color">Referrals</span>
            </h1>
          </div>
          <div className="landing-header-des">
            Don't just apply, get noticed! Our platform connects you with ideal
            referral partners, while expert career advice can be combined with
            resume and cover letter assistance to give your application an extra
            edge.
          </div>
          <div className="landing-header-button-container">
            <CustomButton
              label="Get Started →"
              onClick={() => console.log("Button clicked!")}
              type="submit"
            />
            <CustomButton
              label="Learn More"
              onClick={() => console.log("Button clicked!")}
              type="green-border"
            />
          </div>
        </div>
        {/* <div className="landing-header-image-container"> */}
        <img
          className="landing-header-image"
          src={headerImage}
          alt="landing-header-image"
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Header;
