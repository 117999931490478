import React, { useState } from "react";
import "./CareerAdvice.css";
import TitlePath from "../../../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import chatIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import createIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/createIcon.svg";
import checkIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/checkIcon.svg";
import saveIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/saveIcon.svg";
import arrowBack from "../../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import calendar from "../../../../../assets/images/adminLayout/Dashboard/Icons/calendar.svg";

const CareerAdvice = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    link: "",
    date: "",
    time: "",
  });

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `Service Management`,
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        {/* Header */}
        <div className="jra-header">
          <div className="jra-header-title">
            {/* <span>←</span> */}
            <img src={arrowBack} className="jra-header-title-icon" />
            <h4>
              Career Advice -{" "}
              <span className="green-heading">Amelia Clarke - JR45845</span>
            </h4>
          </div>
          <div className="jra-header-actions">
            <select className="jra-status-select">
              <option>Mark Status</option>
            </select>
            <button className="jra-chat-button">
              <img src={chatIcon} className="chatIcon" />
              Chat
              <span className="jra-notification-badge">15</span>
            </button>
          </div>
        </div>

        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Advice Title</p>
              <p className="jra-status-value">Career Coaching </p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Created Date</p>
                <p className="jra-status-value">2024-07-30 </p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">Completed</span>
              </div>
            </div>
          </div>

          <div>
            <p className="jra-status-label">Description</p>
            <p className="jra-status-value">
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
            </p>
          </div>
        </div>

        <div className="jra-heading-container">
          <h5 className="jra-heading">Meeting details</h5>
          <button className="jra-save-button">
            <img src={saveIcon} className="save-icon" />
            Save
          </button>
        </div>
        <form className="meeting-form jra-card">
          <div className="career-form-input-group">
            <label className="jra-status-label">Link</label>
            <input
              type="text"
              id="link"
              name="link"
              className="meeting-input"
              value={formData.link}
              onChange={handleChange}
              placeholder="Enter your Meeting Link"
            />
          </div>

          <div className="career-form-group">
            <label className="jra-status-label">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              className="meeting-date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>

          <div className="career-form-group">
            <label className="jra-status-label">Time</label>
            <input
              type="time"
              id="time"
              name="time"
              className="meeting-time"
              value={formData.time}
              onChange={handleChange}
            />
          </div>
        </form>

        {/* Industry Selection */}
        <h5 className="jra-heading">Feedback</h5>
        <div className="jra-card">
          <div className="jra-status-value">
            Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur
            adipis cing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum
            dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor
            incididunt ut Lorem ipsum dolor sit amet, consectetur adipis cing
            elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit
            amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt
            ut Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet,
            consectetur adipis cing elit, sed do eiusmod tempor incididunt ut
            Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut.
          </div>
        </div>

        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">REF123456789</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">August 19, 2024</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">Credit Card</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">$100</span>
            </div>
          </div>
        </div>

        {/* Status Timeline */}
        <h5 className="jra-heading">Status</h5>
        <div className="jra-card">
          <div className="jra-timeline">
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={createIcon} />
              <div className="jra-timeline-label">Created</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>

            <hr className="jra-timeline-career-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={calendar} />
              <div className="jra-timeline-label">Meeting Schedule</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>

            <hr className="jra-timeline-career-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={checkIcon} />
              <div className="jra-timeline-label">Completed</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CareerAdvice;
