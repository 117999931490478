import React, { useState } from "react";
import "./JobReferrals.css";
import TitlePath from "../../../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import chatIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import createIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/createIcon.svg";
import alarmIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/alarmIcon.svg";
import checkIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/checkIcon.svg";
import hourglassIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/hourglassIcon.svg";
import arrowBack from "../../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import outbox from "../../../../../assets/images/adminLayout/Dashboard/Icons/outbox.svg";

const JobReferrals = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `Service Management`,
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        {/* Header */}
        <div className="jra-header">
          <div className="jra-header-title">
            {/* <span>←</span> */}
            <img src={arrowBack} className="jra-header-title-icon" />
            <h4>
              Job Referrals -{" "}
              <span className="green-heading">Amelia Clarke - JR45845</span>
            </h4>
          </div>
          <div className="jra-header-actions">
            <select className="jra-status-select">
              <option>Mark Status</option>
            </select>
            <button className="jra-chat-button">
              <img src={chatIcon} className="chatIcon" />
              Chat
              <span className="jra-notification-badge">15</span>
            </button>
          </div>
        </div>

        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">Amelia Clarke</p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">2024-07-30</p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">Approved</span>
              </div>
            </div>
          </div>

          <div>
            <p className="jra-status-label">Reject Reason</p>
            <p className="jra-status-value">
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
            </p>
          </div>
        </div>

        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">Amelia.Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
          </div>
        </div>

        {/* Industry Selection */}
        <h5 className="jra-heading">Industry Selection</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Industry</p>
              <p className="jra-status-value">Information Technology</p>
            </div>
            <div>
              <p className="jra-status-label">Desired Position</p>
              <p className="jra-status-value">Team Lead</p>
            </div>
            <div>
              <p className="jra-status-label">Desired Company</p>
              <p className="jra-status-value">Quantum Solutions</p>
            </div>
            <div>
              <p className="jra-status-label">Current Job</p>
              <p className="jra-status-value">
                Software Engineer at NextGen Pvt Ltd
              </p>
            </div>
          </div>
        </div>

        {/* Application Details */}
        <h5 className="jra-heading">Application Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Resume</p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                ABCorpDid.pdf
              </a>
            </div>
            <div>
              <p className="jra-status-label">Cover Letter</p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                ABCorpDid.doc
              </a>
            </div>
          </div>
        </div>

        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">REF123456789</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">August 19, 2024</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">Credit Card</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">$100</span>
            </div>
          </div>
        </div>

        {/* Status Timeline */}
        <h5 className="jra-heading">Status</h5>
        <div className="jra-card">
          <div className="jra-timeline">
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={createIcon} />
              <div className="jra-timeline-label">Created</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={alarmIcon} />
              <div className="jra-timeline-label">Progress Started</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={hourglassIcon} />
              <div className="jra-timeline-label">Waiting for Approval</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={outbox} />
              <div className="jra-timeline-label">Submit Proof</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={checkIcon} />
              <div className="jra-timeline-label">Approved</div>
              <div className="jra-timeline-date">August 19, 2024</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobReferrals;
