import { useState } from "react";
import Navbar from "../simpleLayout/navbar/Navbar";
import Footer from "../simpleLayout/footer/Footer";
import Main from "./Main/Main";
import Header from "./Header/Header";

export default function ReffererVarificationLayout() {
  const [open, setOpen] = useState(false);

  return (
    <div className="dashboard-container referrer-layout-main-container">
      <Navbar />
      <Header />
      <Main />
      <Footer />
    </div>
  );
}
