import React, { useState } from "react";
import "./RefereeVerification.css";
import registrationIcon from "../../../assets/images/ReferrerLayout/Icons/registration.svg";
import verificationIcon from "../../../assets/images/ReferrerLayout/Icons/verification.svg";
import earningIcon from "../../../assets/images/ReferrerLayout/Icons/earning.svg";
import encrypted_add from "../../../assets/images/ReferrerLayout/Icons/encrypted_add.svg";
import accountCircle from "../../../assets/images/ReferrerLayout/Icons/accountCircle.svg";
import refresh from "../../../assets/images/ReferrerLayout/Icons/refresh.svg";
const RefereeVerification = ({isReferrer}) => {
  const [pendingRequest, setPendingRequest] = useState(false);
  const [rejectRequest, setRejectRequest] = useState(false);

  const handleVerify = () => {
    setPendingRequest(true);
  };

  return (
    <div className="referee-container">
      {!isReferrer=="referrer" ? (
        <h1 className="referee-title">Referee Registration & Verification</h1>
      ) : (
        <div></div>
      )}
      <div className="timeline-container">
        {/* Timeline line */}
        {/* <div className="timeline-line"/> */}

        {/* Registration Section */}
        <div className="timeline-item">
          <div className="icon-container">
            <div className="icon-wrapper icon-registration">
              <img src={registrationIcon} className="icon" />
            </div>
            <div className="timeline-line" />
          </div>
          <div className="content-wrapper">
            <div className="content-card">
              <h2 className="section-title">Registration</h2>
              <p className="section-text">
                Sign up on our website as a Referee and gain access to a wide
                range of job referral applications created by job seekers.
              </p>
            </div>
          </div>
        </div>

        {/* Verification Section */}
        <div className="timeline-item">
          <div className="icon-container">
            <div className="icon-wrapper icon-verification">
              <img src={verificationIcon} className="icon" />
            </div>
            <div className="timeline-line" />
          </div>
          <div className="content-wrapper">
            <div className="content-card">
              <h2 className="section-title">Verification</h2>
              <p className="section-text">
                After registration, you must complete the account verification
                process. This step is essential to confirm that you are an
                employee of the specified company, ensuring the integrity and
                security of our platform. Only verified Referees can access job
                referral applications.
              </p>

              {!pendingRequest && !rejectRequest ? (
                <div className="referee-verification-button-container">
                  <button
                    className="button-common user-dashboard-table-button"
                    onClick={handleVerify}
                  >
                    <img
                      src={encrypted_add}
                      alt="Verify Icon"
                      className="user-dashboard-table-button-icon"
                    />
                    Verify Now
                  </button>
                </div>
              ) : (
                <div></div>
              )}

              {pendingRequest ? (
                <div className="referee-verification-button-container">
                  <h5 className="referee-verification-heading">
                    Verification Status
                  </h5>
                  <button className="button-common referee-verification-button">
                    Pending
                  </button>
                  <p className="referee-verification-des">
                    The verification process may take up to 14 days. Please wait
                    patiently.
                  </p>
                  <button className="button-common user-dashboard-table-button pay-cancel-button">
                    View Application
                  </button>
                </div>
              ) : (
                <div></div>
              )}

              {rejectRequest ? (
                <div className="referee-verification-button-container">
                  <h5 className="referee-verification-heading-red">
                    Verification Status
                  </h5>
                  <button className="button-common referee-verification-button-red">
                    Rejected
                  </button>
                  <p className="referee-verification-des referee-verification-rejected-des">
                    Unfortunately, your profile verification has been rejected.
                    Please review the feedback provided, make the necessary
                    adjustments, and resubmit your application for verification.
                  </p>
                  <div className="referee-verification-rejected-button-container">
                    <button className="button-common user-dashboard-table-button pay-cancel-button referee-verification-button-width">
                      <img
                        src={accountCircle}
                        className="referee-verification-rejected-button-icon"
                      />
                      View work Profile
                    </button>
                    <button className="button-common user-dashboard-table-button pay-cancel-button referee-verification-button-width">
                      <img
                        src={refresh}
                        className="referee-verification-rejected-button-icon"
                      />
                      Resubmit
                    </button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>

        {/* Earning Section */}
        <div className="timeline-item">
          <div className="icon-container">
            <div className="icon-wrapper icon-earning">
              <img src={earningIcon} className="icon" />
            </div>
          </div>
          <div className="content-wrapper">
            <div className="content-card">
              <h2 className="section-title">Earning</h2>
              <ul className="earning-list">
                <li className="earning-item">
                  Viewing Applications: Once verified, browse through
                  applications that specify the desired company and role. Each
                  application includes the candidate's CV, cover letter, and
                  personal details.
                </li>
                <li className="earning-item">
                  Recommendation: Assess the suitability of candidates for
                  specific roles and companies. If you find a candidate that
                  meets the criteria, you can recommend them to the company.
                </li>
                <li className="earning-item">
                  Approval Process: When a candidate you recommended gets hired,
                  the application is marked as approved by the job seeker. After
                  verification by the client, you earn credits.
                </li>
                <li className="earning-item">
                  Withdraw Money: Accumulate credits for each successful
                  recommendation. These credits can be withdrawn directly to
                  your bank account, providing a rewarding experience for your
                  efforts in helping companies find the right talent.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereeVerification;
