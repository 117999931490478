// Navbar.js
import React, { useState } from "react";
import "./Footer.css";
import footerLogo from "../../../assets/images/konect-logo.svg";
import facebookIcon from "../../../assets/images/facebookIcon.svg";
import linkdinIcon from "../../../assets/images/linkdinIcon.svg";
import instaIcon from "../../../assets/images/instaIcon.svg";
import youtubeIcon from "../../../assets/images/youtubeIcon.svg";
import twiterIcon from "../../../assets/images/twiterIcon.svg";

const Footer = () => {
  const scrollToSection = (id) => {
    // alert(`Navigating to section ${id}`);
    const element = document.getElementById(id);
    if (element) {
      // alert("Scrolling to section");
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <footer>
      <div class="footer-container">
        <section class="footer-about">
          <img className="company-icon" src={footerLogo} alt="company-icon" />
          <p>
            Connecting job seekers with experienced industry professionals to
            create equality and opportunity in the job search process.
          </p>
          <div className="contact-social-container">
            <section class="footer-social">
              <h6>Follow Us</h6>
              <ul className="contact-social-media-container">
                <a href="https://facebook.com" target="_blank">
                  <img className="contact-social-media" src={facebookIcon} />
                </a>
                <a href="https://twitter.com" target="_blank">
                  <img className="contact-social-media" src={linkdinIcon} />
                </a>
                <a href="https://instagram.com" target="_blank">
                  <img className="contact-social-media" src={instaIcon} />
                </a>
                <a target="_blank">
                  <img className="contact-social-media" src={youtubeIcon} />
                </a>
                <a target="_blank">
                  <img className="contact-social-media" src={twiterIcon} />
                </a>
              </ul>
            </section>
          </div>
        </section>
        <section class="footer-links">
          <h6>Konect</h6>
          <ul>
            <li>
              <a>Our Services</a>
            </li>
            <li>
              <a>Learning Hub</a>
            </li>
            <li>
              <a>Discussion Forum</a>
            </li>
            <li>
              <a>About Us</a>
            </li>
            <li>
              <a>Help</a>
            </li>
          </ul>
        </section>

        <section class="footer-links">
          <h6>Our Services</h6>
          <ul>
            <li>
              <a>Receive Job Referrals</a>
            </li>
            <li>
              <a>Provide Job Referrals</a>
            </li>
            {/* <li>
              <a>Receive Career Advice</a>
            </li>
            <li>
              <a>Provide Career Advice</a>
            </li> */}
            <li>
              <a>Receive Resume & Cover Letter Assistance</a>
            </li>
            {/* <li>
              <a>Provide Resume & Cover Letter Assistance</a>
            </li> */}
          </ul>
        </section>

        <section class="footer-links learnig-hub-footer-column">
          <h6>Learning Hub</h6>
          <ul>
            <li>
              <a>Webinars</a>
            </li>
            <li>
              <a>Articles</a>
            </li>
            <li>
              <a>Quizzes</a>
            </li>
          </ul>
        </section>

        <section class="footer-links">
          <h6>Help</h6>
          <ul>
            <li>
              <a>FAQs</a>
            </li>
            <li>
              <a>Contact Form</a>
            </li>
            <li>
              <a>Provide Feedback</a>
            </li>
          </ul>
        </section>
      </div>
      <hr class="centered-hr"></hr>
      <div class="footer-bottom">
        <div>© 2024 Konect Pvt Ltd. All Rights Reserved.</div>
        <div>
          <div>Privacy Policy</div>
          <div>Cookie Policy</div>
          <div>Terms & Conditions</div>
          <div>Accessibility Policy</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
