import React from "react";
import ContactForm from "../containers/ContactForm/ContactForm";

const ContactFormPage = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>Contact Form</h1>
        <p>
          We’re here to assist you. Whether you have questions or need support,
          reach out to us!
        </p>
      </div>
      <div className="main-home-container">
        <ContactForm/>
      </div>
    </>
  );
};

export default ContactFormPage;
