import React, { useState } from "react";
import "./LearningHubDetails.css";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import DashboardHeading from "../../../../components/DashboardHeading/DashboardHeading";
import learninghubimg from "../../../../assets/images/learninghubImg1.svg";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import user from "../../../../assets/images/people5.jpg";
import editIcon from "../../../../assets/images/Icons/edit_icon.svg";

const LearningHubDetails = () => {
  const articleData = {
    name: "Mitchell Johnson",
    date: "27 April 2024",
    heading: "Mastering Job Interviews: Tips from Top Recruiters",
    des: "Landing a job interview is a significant step in your career journey, but mastering the interview itself is where the real challenge lies. To help you succeed, we’ve gathered insights from top recruiters. Before you step into the interview room, make sure you know the company inside out. Top recruiters emphasize the importance of understanding the company’s mission, values, products, and recent achievements. This knowledge demonstrates your genuine interest and helps you tailor your responses to align with the company’s goals.",
  };

  const tags = [
    {
      tag: "Job Interview",
    },
    {
      tag: "Guidance",
    },
    {
      tag: "Talent Acquisition ",
    },
  ];

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const handleBackTable = () => {
    navigate(`/admin_dashboard/learning_hub`);
  };

  const titlePath = {
    exactPath: `CD001`,
    paths: [
      { label: "Dashboard >", onClick: handleBack },
      { label: "Discussion Forum > ", onClick: handleBackTable },
    ],
  };
  const headingDes = {
    heading: "Learning Hub",
    des: "Oversee content and user progress to ensure a seamless and effective learning experience.",
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="setting-page-container">
        <div className="dashboard-learninghub-img-container">
          <div>
            <img className="dashboard-learninghub-img" src={learninghubimg} />
          </div>
          <div>
            <div className="card-header">
              <h5>Author</h5>
              <button
                onClick={() => console.log("Button clicked!")}
                className="button-with-icon-green"
              >
                <img src={editIcon} className="button-icon" />
                Edit
              </button>
            </div>
            <div className="user-info">
              <div className="postby-user-img-container">
                <img className="postby-user-img" src={user} alt="host" />
              </div>
              <div>
                <h6>Alex Carl</h6>
                <p className="post-user-email">Alexcarl07@gmail.com</p>
              </div>
            </div>

            <div className="post-details">
              <div>
                <p className="postby-heading">Content ID</p>
                {/* <p className="postby-des">CD001</p> */}
                <CustomButton
                  label="Article"
                  onClick={() => console.log("Button clicked!")}
                  type="dashboard-category-button"
                />
              </div>
              <div>
                <p className="postby-heading">Category</p>
                <p className="postby-des">12 Mar 2022</p>
              </div>
            </div>

            <div className="post-details">
              <div>
                <p className="postby-heading">Date Published</p>
                {/* <p className="postby-des">CD001</p> */}
                <p className="postby-des">12 Mar 2022</p>
              </div>
              <div>
                <p className="postby-heading">Status</p>
                <CustomButton
                  label="Published"
                  onClick={() => console.log("Button clicked!")}
                  type="dashboard-status-button"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="articleview-des-container">
          {/* <div className="learninghub-name-date-container">
            <div>{articleData.name}</div>
            <div>{articleData.date}</div>
          </div> */}
          <h3 className="articleview-heading">{articleData.heading}</h3>
          <p className="articleview-des">{articleData.des}</p>
          <div className="tag-container">
            <p className="articleview-des">Tags</p>
            {tags.map((data) => (
              <CustomButton
                label={data.tag}
                onClick={() => console.log("Button clicked!")}
                type="tag-button"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default LearningHubDetails;
