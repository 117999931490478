import React from "react";
import "./Services.css";
import ServicesIcon from "../../assets/images/cv-png.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const Services = () => {
  const ServicesData = [
    // {
    //   heading: "Services",
    //   des: "Get personalised guidance on your career path from experienced professionals. Connect with current employees who can offer valuable insights on career building, interview skills, and navigating the job market for your target industry.",
    // },
    {
      heading: "Receive Resume & Cover Letter Assistance",
      des: "Get your application materials polished and ready to impress. Konect connects you with professionals who can review your resume and cover letter, ensuring they showcase your skills and experience effectively.",
    },
    // {
    //   heading: "Provide Career Advice",
    //   des: "Get paid to share your knowledge and expertise with job seekers on Konect. Help others achieve their career goals by offering guidance on navigating the job search process.",
    // },
    // {
    //   heading: "Provide Resume & Cover Letter Assistance",
    //   des: "Help job seekers land their dream jobs by offering resume and cover letter writing assistance on Konect. Leverage your skills to provide valuable feedback and ensure their application materials stand out.",
    // },
  ];
  return (
    <div className="main-services-container">
      <h1 className="services-heading">
       Get Your Free
        <span className="heading-different-color"> Resume Review</span>
      </h1>
      <div className="services-heading-des">
        Discover how Konect can help you advance your career with personalized
        support and expert guidance
      </div>
      <div className="services-container">
        {ServicesData.map((data) => (
          <div className="sub-services-container">
            <div>
              <img
                className="services-icon-image"
                src={ServicesIcon}
                alt="services-icon-image"
              />

              <div className="services-sub-heading">{data.heading}</div>
              <div className="services-des">{data.des}</div>
              <CustomButton
                label="Learn More →"
                onClick={() => console.log("Button clicked!")}
                type="services-green-border"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
