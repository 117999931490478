import React, { useEffect, useState } from "react";
import TitlePath from "../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import DashboardSubNavbar from "../../../components/DashboardSubNavbar/DashboardSubNavbar";
import AccountSetting from "../../DashboardLayout/AccountSetting/AccountSetting";
import "./Setting.css";
import NotificationSetting from "../../DashboardLayout/NotificationSetting/NotificationSetting";

export default function Index() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: `discussion_forum`,
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
      // { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Setting",
    des: "Manage your Learning Hub's articles, webinars, and quizzes",
  };

  const subNav = [
    { navHeading: "Account Settings" },
    { navHeading: "Notification Settings" },
  ];

  const [navClick, setNavClick] = useState("account");
  const [heading, setHeading] = useState("Account Settings")
  const [activeHeading, setActiveHeading] = useState("Account Settings");

  useEffect(() => {
    if(heading=="Account Settings" ){
      console.log("account"); 
      setNavClick("account")
     }
     else{
       setNavClick("notification")
     }
    console.log("abc", navClick, navClick == "account");
  }, [heading])
  
console.log("abc", navClick, navClick == "account");

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      <div className="setting-page-container">
        <DashboardSubNavbar subNav={subNav} setNavClick={setNavClick} setHeading={setHeading} activeHeading={activeHeading} setActiveHeading={setActiveHeading}/>
        <div className="sub-setting-page-container">
          {navClick == "account" ? <AccountSetting privilege='admin' /> : <NotificationSetting />}
        </div>
      </div>
    </div>
  );
}
