import React from "react";
import "./WhoCanUse.css";
import WhoCanUseImage from "../../assets/images/user-role-title.svg"; // Renamed import
import CustomButton from "../../components/CustomButton/CustomButton";

const WhoCanUse = () => {
  return (
    <div className="WhoCanUse">
      <div className="whocanuse-des-container">
        <div>Job Seekers</div>
        <div>
          Connect with referrers who are currently employed in your desired
          positions to receive referrals and career advice. Additionally, make
          yourself stand out even more with our resume and cover letter
          assistance service.
        </div>
        {/* <CustomButton
          label="Get Started →"
          onClick={() => console.log("Button clicked!")}
          type="submit"
        /> */}
      </div>
      {/* <div className="whocanuse-img-container"> */}
        <img
          className="whocanuse-image"
          src={WhoCanUseImage} // Updated to use the renamed import
          alt="WhoCanUse-image"
        />
      {/* </div> */}
      <div className="whocanuse-des-container">
        <div>Referees</div>
        <div>
          Help job seekers by providing job referrals and career advice as a
          current employee at their desired position. Also get paid by
          leveraging your experience to provide resume and cover letter
          assistance.
        </div>
        {/* <CustomButton
          label="Get Started →"
          onClick={() => console.log("Button clicked!")}
          type="submit"
        /> */}
      </div>
    </div>
  );
};

export default WhoCanUse;
