import React from "react";
import ContactForm from "../containers/ContactForm/ContactForm";
import ProvideFeedback from "../containers/ContactForm/ProvideFeedback";

const ProvideFeedbackPage = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>Provide Feedback</h1>
        <p>Help us enhance your experience with your valuable feedback</p>
      </div>
      <div className="main-home-container">
        <ProvideFeedback/>
      </div>
    </>
  );
};

export default ProvideFeedbackPage;
