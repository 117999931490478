import React, { useState, useRef } from "react";
import "./CreateNewDiscussion.css";
import CustomButton from "../../components/CustomButton/CustomButton";

const CreateNewDiscussion = () => {
  return (
    <div className="create-post-container">
      <h4 className="create-post-title">
        Create Post - Start a New Discussion
      </h4>
      <form className="create-post-form">
        <div className="form-group">
          <div className="discussion-input-label">Discussion Title</div>
          <input
            type="text"
            id="title"
            maxLength="300"
            placeholder="Enter your discussion title here"
            className="input-title"
          />
          <div className="character-count">0/300</div>
        </div>
        <div className="form-group">
          <div className="discussion-input-label">Content</div>
          <textarea
            id="content"
            placeholder="What are your thoughts?"
            className="input-content"
          ></textarea>
          <div className="toolbar">
            <button type="button">
              <strong>B</strong>
            </button>
            <button type="button">
              <em>I</em>
            </button>
            <button type="button">
              <u>U</u>
            </button>
            <button type="button">{`</>`}</button>
            <select className="font-select">
              <option value="normal">Normal text</option>
            </select>
            {/* <button type="button">&#8226;&#8226;&#8226;</button>
            <button type="button">&#189;</button> */}
            <button type="button">&#128279;</button>
            <button type="button">&#60;&#47;&#62;</button>
            <button type="button">&#8220;&#8221;</button>
            <button type="button">&#8212;</button>
          </div>
        </div>
        <div className="form-group">
          <div className="discussion-input-label">Tags</div>
          <input
            type="text"
            id="tags"
            placeholder="Add tags..."
            className="input-tags"
          />
        </div>
        <div className="contact-form-button-container">
          <CustomButton
            label="Cancel"
            onClick={() => console.log("Button clicked!")}
            type="contact-form-button"
          />
          <CustomButton
            label="Publish"
            onClick={() => console.log("Button clicked!")}
            type="contact-form-button-purple"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateNewDiscussion;
