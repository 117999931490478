import React, { useState } from "react";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import { useNavigate } from "react-router-dom";
import chatIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import "./UserResumeReviewDetails.css";
import arrowBack from "../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import saveIcon from "../../../../assets/images/UserLayout/Icons/save_icon.svg";
import closeIcon from "../../../../assets/images/UserLayout/Icons/close.svg";

const UserResumeReviewDetails = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/user_dashboard`);
  };
  const handleBackTable = () => {
    navigate(`/user_dashboard/resume_review_table`);
  };

  const titlePath = {
    exactPath: `Amelia Clarke-JR45845`,
    paths: [
      { label: "Services >", onClick: handleBack },
      { label: "Resume Review > ", onClick: handleBackTable },
    ],
  };
  return (
    <div className="main-dashboard-container user-job-referrals-details-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        <div className="jra-header">
          <div className="jra-header-title">
            <h4 className="jra-header-heading">
              Resume & Cover Letter Review & Assistance
            </h4>
          </div>
          <div className="jra-header-actions">
            {/* <select className="jra-status-select">
              <option>Mark Status</option>
            </select> */}
            <button className="jra-chat-button">
              <img src={chatIcon} className="chatIcon" />
              Chat
              <span className="jra-notification-badge">15</span>
            </button>
          </div>
        </div>

        <p className="user-resume-details-des">
          Get expert feedback on your resume and cover letter to improve your
          chances of landing the job you want. Submit a request and receive
          personalized assistance.{" "}
        </p>

        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">Amelia Clarke</p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">2024-07-30</p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">Approved</span>
              </div>
            </div>
          </div>
        </div>

        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">Amelia.Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
          </div>
        </div>

        {/* Industry Selection */}
        <h5 className="jra-heading">Industry Selection</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Industry</p>
              <p className="jra-status-value">Information Technology</p>
            </div>
            <div>
              <p className="jra-status-label">Desired Position</p>
              <p className="jra-status-value">Team Lead</p>
            </div>
            <div>
              <p className="jra-status-label">Desired Company</p>
              <p className="jra-status-value">Quantum Solutions</p>
            </div>
            <div>
              <p className="jra-status-label">Current Job</p>
              <p className="jra-status-value">
                Software Engineer at NextGen Pvt Ltd
              </p>
            </div>
          </div>
        </div>

        {/* Application Details */}
        <h5 className="jra-heading">Application Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Resume</p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                ABCorpDid.pdf
              </a>
            </div>
            <div>
              <p className="jra-status-label">Cover Letter</p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                ABCorpDid.doc
              </a>
            </div>
          </div>
        </div>

        {/*Review*/}
        <h5 className="jra-heading">Review</h5>
        <div className="jra-card">
          <p className="jra-status-label">Submitted Information:</p>
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label-colour">AI-powered Reviewed CV</p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                AI_Reviewed_Resume.pdf
              </a>
            </div>
            <div>
              <p className="jra-status-label-colour">
                CV Consultant Reviewed CV
              </p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                Consultant_Reviewed_Resume.pdf
              </a>
            </div>
          </div>
          <p className="jra-status-label">CV Reviewer Comments</p>
          <p className="jra-status-label-colour">AI-powered Reviewed CV</p>
          <p className="jra-status-label-colour">
            Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur
            adipis cing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum
            dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor
            incididunt ut Lorem ipsum dolor sit amet, consectetur adipis cing
            elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit
            amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt
            ut Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet,
            consectetur adipis cing elit, sed do eiusmod tempor incididunt ut
            Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut.
          </p>

          <p className="jra-status-label-colour">
            CV Consultant Review Comments
          </p>
          <p className="jra-status-label-colour">
            Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut Lorem ipsum dolor sit amet, consectetur
            adipis cing elit, sed do eiusmod tempor incididunt ut. Lorem ipsum
            dolor sit amet, consectetur adipis cing elit, sed do eiusmod tempor
            incididunt ut Lorem ipsum dolor sit amet, consectetur adipis cing
            elit, sed do eiusmod tempor incididunt ut. Lorem ipsum dolor sit
            amet, consectetur adipis cing elit, sed do eiusmod tempor incididunt
            ut Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut. Lorem ipsum dolor sit amet,
            consectetur adipis cing elit, sed do eiusmod tempor incididunt ut
            Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
            eiusmod tempor incididunt ut.
          </p>
        </div>

        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">REF123456789</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">August 19, 2024</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">Credit Card</span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">$100</span>
            </div>
          </div>
        </div>

        {/* Feedback */}
        <h5 className="jra-heading">Feedback</h5>
        <div className="jra-card">
          <textarea
            className="contact-form-textarea user-request-form"
            placeholder="Enter your feedback here...          "
            rows="10"
          />
        </div>
      </div>
      <div className="user-request-form-actions">
        <button className="button-common user-dashboard-table-button pay-cancel-button">
          <img src={closeIcon} class="user-dashboard-table-button-icon" />
          Cancel
        </button>
        <button className="button-common user-dashboard-table-button">
          <img src={saveIcon} class="user-dashboard-table-button-icon" />
          Submit
        </button>
      </div>
    </div>
  );
};
export default UserResumeReviewDetails;
