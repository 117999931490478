// Navbar.js
import React, { useEffect, useState } from "react";
import "./Navbar.css";
import CustomButton from "../../../components/CustomButton/CustomButton";
import Logo from "../../../assets/images/konect-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import SignInModal from "../../../components/SignInModal/SignInModal";
const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);
  const [otpVarification, setOtpVarification] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false)
    setIsLoginModal(false)
    setIsCreatePassword(false)
    setOtpVarification(false)
  }

  const handleLoginClick=()=>{
    setIsLoginModal(true)
    setIsModalOpen(true)
  }

  const handleClick = () => {
    alert("Button clicked!");
  };
  const navigate = useNavigate();
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const scrollToSection = (id) => {
    // alert(`Navigating to section ${id}`);
    const element = document.getElementById(id);
    if (element) {
      // alert("Scrolling to section");
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 1023) {
        setMenuOpen(false);
      } else {
        setMenuOpen(true);
      }
    };
    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const links = [
    {
      text: "Our Services",
      href: "/ourServices",
    },
    {
      text: "Learning Hub",
      href: "/learningHub",
    },
    {
      text: "Discussion Forum",
      href: "/discussionForum",
    },
    {
      text: "About Us",
      href: "/aboutus",
    },
    {
      text: "Help",
      href: "/helpcenter",
    },
  ];
  return (
    <header>
      <nav className="nav-container">
        <div className="navbar-container">
          <div className="logo-container">
            <a href="/" className="logo-link">
              <img src={Logo} alt="Bio World Logo" className="logo-image" />
            </a>
          </div>

          {menuOpen && (
            <ul>
              {/* <div className="navbar-container"> */}
              {links.map((link, index) => (
                <li key={index} className="nav-menu-item">
                  <Link
                    to={link?.href ? link.href : ""}
                    onClick={link?.onClick ? link?.onClick : null}
                  >
                    {link.text}
                  </Link>
                </li>
              ))}
              {/* </div> */}

              <li></li>
              <li>
                <CustomButton
                  label="Get Started →"
                  onClick={handleLoginClick}
                  type="submit signin-button"
                />
                <CustomButton
                  label="Sign In"
                  onClick={openModal}
                  type="green-border signin-button"
                />
              </li>
            </ul>
          )}
          <div className="menu" onClick={handleMenuClick}>
            {!menuOpen ? (
              <i className="fa-solid fa-bars" style={{ color: "white" }}></i>
            ) : (
              <i class="fa-solid fa-xmark" style={{ color: "white" }}></i>
            )}
          </div>
        </div>
      </nav>
      {/* <KonectModal isOpen={isModalOpen} onClose={closeModal} /> */}
      <SignInModal
        isOpen={isModalOpen}
        onClose={closeModal}
        setIsLoginModal={setIsLoginModal}
        isLoginModal={isLoginModal}
        isCreatePassword={isCreatePassword}
        setIsCreatePassword={setIsCreatePassword}
        otpVarification={otpVarification}
        setOtpVarification={setOtpVarification}
      />
    </header>
  );
};

export default Navbar;
