import React from "react";
import LearningHubArticleView from "../../containers/LearningHub/LearningHubArticleView";
import LearningHubWebinarView from "../../containers/LearningHub/LearningHubWebinarView";

const WebinarViewPage = () => {
  return (
    <>
      <div className="main-home-container">
        <LearningHubWebinarView />
      </div>
    </>
  );
};

export default WebinarViewPage;
