import React from "react";
import "./Dashboard.css";
import JobReferralsImg from "../../../assets/images/UserLayout/Images/JobReferralsImg.png";
import CareerAdviceImg from "../../../assets/images/UserLayout/Images/CareerAdviceImg.png";
import reviewImg from "../../../assets/images/UserLayout/Images/reviewImg.png";
import ArrowForward from "../../../assets/images/UserLayout/Icons/arrow_forward_ios.svg";

export default function Dashboard() {
  const userDashboardData = [
    {
      heading: "Job Referrals",
      des: "Get connected to job opportunities through our referral program.Submit a request and let us help you find your next job.",
      img:JobReferralsImg,
      applicationDetails: [
        {
          applicationHeading: "Total Requests",
          count: 10,
        },
        {
          applicationHeading: "Pending",
          count: 5,
        },
        {
          applicationHeading: "Approved",
          count: 3,
        },
        {
          applicationHeading: "Rejected ",
          count: 2,
        },
      ],
    },
    // {
    //   heading: "Career Advice",
    //   des: "Get personalized career advice from experienced professionals to help  you navigate your career path. Submit a request and schedule a session today.",
    //   img: CareerAdviceImg,
    //   applicationDetails: [
    //     {
    //       applicationHeading: "Total Requests",
    //       count: 8,
    //     },
    //     {
    //       applicationHeading: "Pending",
    //       count: 5,
    //     },
    //     {
    //       applicationHeading: "Completed",
    //       count: 3,
    //     },
    //   ],
    // },
    {
      heading: "Resume & Cover Letter Review & Assistance  ",
      des: "Get expert feedback on your resume and cover letter to improve your chances of landing the job you want. Submit a request and receive personalized assistance.",
      img: reviewImg,
      applicationDetails: [
        {
          applicationHeading: "Total Requests",
          count: 10,
        },
        {
          applicationHeading: "Pending",
          count: 5,
        },
        {
          applicationHeading: "Completed",
          count: 3,
        },
      ],
    },
  ];

  return (
    <div className="user-dashboard-container">
      {userDashboardData.map((data) => (
        <div className="main-user-dashboard-container">
          <div>
            <div className="user-jobreferrals-details-container">
              <h4>{data.heading}</h4>
              <p>{data.des}</p>
            </div>
            <div>
              {" "}
              <img src={data.img} />
            </div>
          </div>
          <div>
            <h5 className="user-application-details-heading">
              Application Details
            </h5>
            <div className="user-application-details-container">
              <div>
                {data.applicationDetails.map((details) => (
                  <div>
                    <div>{details.applicationHeading}</div>
                    <div>{details.count}</div>
                  </div>
                ))}
              </div>
              <div className="view-more-button-container">
                <button className="view-more-button">
                  View More
                  <img className="button-arrow-icon" src={ArrowForward} />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
