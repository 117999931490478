import React from "react";
import DiscussionForumFilter from "../../containers/DiscussionForum/DiscussionForumFilter";
import CreateNewDiscussion from "../../containers/DiscussionForum/CreateNewDiscussion";
// import RecentlyVisited from "../../containers/DiscussionForum/RecentlyVisited";

const DiscussionForumPage = () => {
  return (
    <>
      <div className="main-home-container">
        <div className="main-discussion-form-container">
          <DiscussionForumFilter />
          <CreateNewDiscussion/>
          {/* <RecentlyVisited/> */}
        </div>
      </div>
    </>
  );
};

export default DiscussionForumPage;
