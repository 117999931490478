
// Authentication Action Types
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_SEND_OTP_SUCCESS = 'FORGOT_PASSWORD_SEND_OTP_SUCCESS';
export const FORGOT_PASSWORD_SEND_OTP_FAILURE = 'FORGOT_PASSWORD_SEND_OTP_FAILURE';
export const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS = 'FORGOT_PASSWORD_VERIFY_OTP_SUCCESS';
export const FORGOT_PASSWORD_VERIFY_OTP_FAILURE = 'FORGOT_PASSWORD_VERIFY_OTP_FAILURE';
export const FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE = 'FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE';

// Create User Success
export const createUserSuccess = (user) => ({
    type: CREATE_USER_SUCCESS,
    payload: user,
  });
  
  // Create User Failure
  export const createUserFailure = (error) => ({
    type: CREATE_USER_FAILURE,
    payload: error,
  });
  
  // Login Success
  export const loginSuccess = (user) => ({
    type: LOGIN_SUCCESS,
    payload: user,
  });
  
  // Login Failure
  export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
  });
  
  // Resend OTP Success
  export const resendOTPSuccess = () => ({
    type: RESEND_OTP_SUCCESS,
  });
  
  // Resend OTP Failure
  export const resendOTPFailure = (error) => ({
    type: RESEND_OTP_FAILURE,
    payload: error,
  });
  
  // Verify OTP Success
  export const verifyOTPSuccess = () => ({
    type: VERIFY_OTP_SUCCESS,
  });
  
  // Verify OTP Failure
  export const verifyOTPFailure = (error) => ({
    type: VERIFY_OTP_FAILURE,
    payload: error,
  });
  
  // Create Password Success
  export const createPasswordSuccess = () => ({
    type: CREATE_PASSWORD_SUCCESS,
  });
  
  // Create Password Failure
  export const createPasswordFailure = (error) => ({
    type: CREATE_PASSWORD_FAILURE,
    payload: error,
  });
  
  // Forgot Password Send OTP Success
  export const forgotPasswordSendOTPSuccess = () => ({
    type: FORGOT_PASSWORD_SEND_OTP_SUCCESS,
  });
  
  // Forgot Password Send OTP Failure
  export const forgotPasswordSendOTPFailure = (error) => ({
    type: FORGOT_PASSWORD_SEND_OTP_FAILURE,
    payload: error,
  });
  
  // Forgot Password Verify OTP Success
  export const forgotPasswordVerifyOTPSuccess = () => ({
    type: FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  });
  
  // Forgot Password Verify OTP Failure
  export const forgotPasswordVerifyOTPFailure = (error) => ({
    type: FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
    payload: error,
  });
  
  // Forgot Password Create Password Success
  export const forgotPasswordCreatePasswordSuccess = () => ({
    type: FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
  });
  
  // Forgot Password Create Password Failure
  export const forgotPasswordCreatePasswordFailure = (error) => ({
    type: FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
    payload: error,
  });
  
