import React, { useState } from "react";
import "./ContactForm.css";
import mobileIcon from "../../assets/images/callIcon.svg";
import mailIcon from "../../assets/images/mailIcon.svg";
import locationIcon from "../../assets/images/locationIcon.svg";
import facebookIcon from "../../assets/images/facebookIcon.svg";
import linkdinIcon from "../../assets/images/linkdinIcon.svg";
import instaIcon from "../../assets/images/instaIcon.svg";
import youtubeIcon from "../../assets/images/youtubeIcon.svg";
import twiterIcon from "../../assets/images/twiterIcon.svg";
import CustomButton from "../../components/CustomButton/CustomButton";

const ProvideFeedback = () => {
  const contactDetails = [
    {
      icon: mobileIcon,
      details: "+1-234-567-890",
    },
    {
      icon: mailIcon,
      details: "support@konect.com",
    },
    {
      icon: locationIcon,
      details: "123 Konect Street, Suite 456, City, Country",
    },
  ];

  const socialMediaIcon = [
    {
      icon: facebookIcon,
    },
    {
      icon: linkdinIcon,
    },
    {
      icon: instaIcon,
    },
    {
      icon: youtubeIcon,
    },
    {
      icon: twiterIcon,
    },
  ];

  const [selectedRating, setSelectedRating] = useState(null);

  const handleRatingClick = (rating) => {
    setSelectedRating(rating);
    console.log(rating);
  };

  return (
    <div className="main-contact-form-container">
      <div className="contact-details-container">
        <div>
          <div className="services-sub-heading">We Value Your Feedback</div>
          <div className="des-paragraph">
            Help us improve by sharing your thoughts, suggestions, and
            experiences. Your feedback is essential to our growth and success.
          </div>
        </div>

        <div>
          <div className="contact-form-sub-heading">Need More Help?</div>
          {contactDetails.map((data) => (
            <div className="contact-icon-details-container">
              <img className="contact-details-icon" src={data.icon} />
              <div>{data.details}</div>
            </div>
          ))}
        </div>

        <div>
          <div className="contact-form-sub-heading">Follow Us</div>
          <div className="contact-social-media-container">
            {socialMediaIcon.map((data) => (
              <img className="contact-social-media" src={data.icon} />
            ))}
          </div>
        </div>
      </div>
      <div className="contact-form-container  feedback-container">
        <div>
          <div className="services-sub-heading">Share Your Feedback</div>
          <div className="des-paragraph">
            Please fill out the form below to share your thoughts and
            experiences with us.
          </div>
        </div>

        <div>
          <div className="contact-form-sub-heading">Name</div>
          <input
            className="contact-form-input"
            placeholder="Enter your full name"
          />
          <div className="contact-form-sub-heading">E-Mail Address</div>
          <input
            className="contact-form-input"
            placeholder="Enter your email address"
          />
          <div className="contact-form-sub-heading">Type of Feedback</div>
          <select className="contact-form-input contact-form-select">
            <option value="" disabled selected>
              Select the category of feedback
            </option>
            <option value="general">General Feedback</option>
            <option value="bug">Report a Bug</option>
            <option value="feature">Feature Request</option>
            <option value="other">Other</option>
          </select>
          <div className="contact-form-sub-heading">Subject</div>
          <input
            className="contact-form-input"
            placeholder="Enter the subject of your inquiry"
          />
          <div className="contact-form-sub-heading">Message</div>
          <textarea
            className="contact-form-textarea"
            placeholder="What are your thoughts?"
            rows="8"
          />
          <div className="contact-form-sub-heading">Rate Your Experience</div>
          <div className="feedback-rating-container">
            {[...Array(10)].map((_, index) => {
              const rating = index + 1;
              return (
                <button
                  key={rating}
                  className={`rating-button ${
                    selectedRating === rating ? "selected" : ""
                  }`}
                  onClick={() => handleRatingClick(rating)}
                >
                  {rating}
                </button>
              );
            })}
          </div>
          <div className="contact-form-button-container">
            <CustomButton
              label="Cancel"
              onClick={() => console.log("Button clicked!")}
              type="contact-form-button"
            />
            <CustomButton
              label="Submit Feedback"
              onClick={() => console.log("Button clicked!")}
              type="contact-form-button-purple"
            />
          </div>
        </div>
      </div>
      <div className="feedback-des-container">
        <div>
          <h6 className="font-family">Why Your Feedback Matters</h6>
          <p className="feedback-des font-family">
            Your feedback helps us understand your needs, improve our services,
            and make informed decisions to enhance your experience.
          </p>
        </div>
        <hr className="feedback-hr" />
        <div>
          <h6 className="font-family">Help Us Improve</h6>
          <p className="feedback-des font-family">
            Your insights are invaluable to us. Please share your feedback and
            help us create a better Konect experience for everyone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProvideFeedback;
