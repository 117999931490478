import React from "react";
// import LearningHubFilter from "../../containers/LearningHub/LearningHubFilter";
// import LearningHub from "../../containers/LearningHub/LearningHub";
import DiscussionForumFilter from "../../containers/DiscussionForum/DiscussionForumFilter";
import DiscussionsContainer from "../../containers/DiscussionForum/DiscussionsContainer";
import RecentlyVisited from "../../containers/DiscussionForum/RecentlyVisited";

const DiscussionForumPage = () => {
  return (
    <>
      <div className="main-header-heading-condatiner">
        <h1>Discussion Forum</h1>
        <p>Engage, Share, and Learn with Fellow Job Seekers and Referees</p>
      </div>
      <div className="main-home-container">
        <div className="main-discussion-form-container">
          <DiscussionForumFilter />
          <DiscussionsContainer />
          <RecentlyVisited/>
        </div>
      </div>
    </>
  );
};

export default DiscussionForumPage;
