import React, { useState } from "react";
import FileUpload from "../../../../components/FileUpload/FileUpload";
import "./JobReferralsNewRequest.css";
import questionCircle from "../../../../assets/images/UserLayout/Icons/question-circle.svg";
import creditCard from "../../../../assets/images/UserLayout/Icons/credit_card.svg";
import closeIcon from "../../../../assets/images/UserLayout/Icons/close.svg";

const JobReferralsNewRequest = () => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);

  const handleResumeUpload = (file) => {
    setResume(file);
  };

  const handleCoverLetterUpload = (file) => {
    setCoverLetter(file);
  };

  return (
    <div className="user-job-referral-form-request-container">
      <h4>New Job Referral Request</h4>
      <p>Please fill in the information below to start the referral process.</p>

      <form className="user-job-referral-form-request">
        <div>
          <h5>User Information Form</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  E-Mail Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  placeholder="Enter your email address"
                  required
                />
              </div>
            </div>
            <div className="user-job-referral-form-input-container">
              <label className="form-label">
                Your Phone Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="phoneNo"
                className="form-input"
                placeholder="Enter your phone number"
                required
              />
            </div>
          </div>
        </div>

        <div>
          <h5>Industry Selection</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Your Industry<span className="required">*</span>
                </label>
                <select name="name" class="form-input" required>
                  <option value="" disabled selected>
                  Choose the industry that matches your desired position
                  </option>
                  <option value="company1">Option 1</option>
                  <option value="company2">Option 2</option>
                </select>
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Desired Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="desiredPosition"
                  className="form-input"
                  placeholder="Enter the URL of your desired position"
                  required
                />
              </div>
            </div>

            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Desired Company<span className="required">*</span>
                </label>
                <select name="name" class="form-input" required>
                  <option value="" disabled selected>
                  Choose a desired company
                  </option>
                  <option value="company1">Option 1</option>
                  <option value="company2">Option 2</option>
                </select>
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Current Job<span className="required">*</span>
                </label>
                <select name="name" class="form-input" required>
                  <option value="" disabled selected>
                  Choose a desired company
                  </option>
                  <option value="company1">Option 1</option>
                  <option value="company2">Option 2</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="upload-section">
            <h5>Upload Section</h5>
            {/* <FileUpload
            label="Upload Your Resume"
            onFileSelect={handleResumeUpload}
          />
          <FileUpload
            label="Upload Your Cover Letter (Optional)"
            onFileSelect={handleCoverLetterUpload}
          /> */}
            <FileUpload
              fileHeading="Upload Your Resume"
              uploadDes="Drag and Drop or Choose your resume to upload"
            />
          </div>

          <div className="upload-section">
            <FileUpload
              fileHeading="Upload Your Cover Letter (Optional)"
              uploadDes="Drag and Drop or Choose your cover letter to upload"
            />
            <p className="upload-des">
              <img src={questionCircle} className="question-circle-icon" />
              Don’t have a Resume or Cover Letter? Click Here
            </p>
          </div>

          {/* <div className="upload-section">
            <p className="resume-file-heading">Description </p>
            <textarea
              className="contact-form-textarea user-request-form"
              placeholder="Enter your description here..."
              rows="10"
            />
          </div> */}
        </div>
        <div className="user-request-form-actions">
          <button className="button-common user-dashboard-table-button pay-cancel-button">
            <img src={closeIcon} class="user-dashboard-table-button-icon" />
            Cancel
          </button>
          <button className="button-common user-dashboard-table-button">
            <img src={creditCard} class="user-dashboard-table-button-icon" />
            Pay $100
          </button>
        </div>
      </form>
    </div>
  );
};

export default JobReferralsNewRequest;
