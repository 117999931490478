// import React from 'react';
// import './FileUpload.css'; // External CSS for file upload

// const FileUpload = ({ label, onFileSelect }) => {
//   const handleFileChange = (event) => {
//     onFileSelect(event.target.files[0]);
//   };

//   return (
//     <div className="file-upload">
//       <label>{label}</label>
//       <div>
//       <input type="file" onChange={handleFileChange} />
//       </div>
//     </div>
//   );
// };

// export default FileUpload;

import React, { useState } from 'react';
import './FileUpload.css';
import FileUploadImg from "../../assets/images/UserLayout/Icons/uploadIcon.svg"

const FileUpload = ({fileHeading ,uploadDes}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [error, setError] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        
        // Validate file type
        const allowedTypes = ['.pdf', '.doc', '.docx'];
        const fileType = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
        
        if (!allowedTypes.includes(fileType)) {
            setError('Please select a PDF or Word document');
            setSelectedFile(null);
            return;
        }

        setSelectedFile(file);
        setError('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (!selectedFile) {
            setError('Please select a file');
            return;
        }

        // Here you would typically handle the file upload to your server
        console.log('File to upload:', selectedFile);
        
        // Example of FormData usage for actual upload
        const formData = new FormData();
        formData.append('file', selectedFile);
        
        // Simulated upload success
        alert('File uploaded successfully!');
    };

    return (
        <div className="resume-file-upload-container">
            <p className='resume-file-heading'>{fileHeading}
              {/* <span className='required'>*</span> */}
              </p>
            <form onSubmit={handleSubmit} className="upload-form">
                <div className="file-input-container">
                    <input
                        type="file"
                        id="file-upload"
                        onChange={handleFileChange}
                        accept=".pdf,.doc,.docx"
                        className="file-input"
                    />
                    <label htmlFor="file-upload" className="file-label">
                       <img src={FileUploadImg}/>
                    </label>
                    <div className="file-name">
                        {selectedFile ? selectedFile.name : uploadDes}
                    </div>
                </div>

                {error && <div className="error-message">{error}</div>}

                {selectedFile && (
                    <div className="file-details">
                        <p>File Size: {(selectedFile.size / 1024 / 1024).toFixed(2)} MB</p>
                        {/* <p>File Type: {selectedFile.type || 'Unknown'}</p> */}
                    </div>
                )}
            </form>
        </div>
    );
};

export default FileUpload;
