import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import DiscussionFormPost from "../../../containers/AdminLayout/DiscussionFormDetails/DiscussionFormDetails";
import DiscussionFormSidebar from "../../../containers/AdminLayout/DiscussionFormSidebar";
import "./DiscussionFormDetails.css"

const DiscussionFormDetails = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const handleBackTable = () => {
    navigate(`/admin_dashboard/discussion_forum`);
  };

  const titlePath = {
    exactPath: `DF001`,
    paths: [
      { label: "Dashboard >", onClick: handleBack },
      { label: "Discussion Forum > ", onClick: handleBackTable },
    ],
  };

  const headingDes = {
    heading: "Discussion Forum",
    des: "Efficiently manage and moderate discussions to maintain a safe and engaging forum environment.",
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="dashboard-discussion-form-details">
        <DiscussionFormSidebar/>
        <DiscussionFormPost/>
      </div>
    </div>
  );
};

export default DiscussionFormDetails;
