import React from "react";
import integration from "../../assets/images/integration-png.svg";
import empowerment from "../../assets/images/empowerment-png.svg";
import community from "../../assets/images/community-png.svg";
import innovation from "../../assets/images/innovation-png.svg";

const Principles = () => {
  const principlesData = [
    {
      icon: integration,
      heading: "Integrity",
      des: "We believe in honesty and transparency in all our interactions. At Konect, we prioritize ethical practices and uphold a strong sense of responsibility to ensure trust and reliability.",
    },
    {
      icon: empowerment,
      heading: "Empowerment",
      des: "We strive to empower job seekers with the connections they need to succeed. Our platform is designed to provide access to resources and mentorship that enable users to navigate their career paths.",
    },
    {
      icon: community,
      heading: "Community",
      des: "We foster a community that helps each other grow. Konect thrives on the strength of our network, where members are encouraged to share knowledge, offer support, and celebrate each other’s successes.",
    },
    {
      icon: innovation,
      heading: "Innovation",
      des: "We are constantly innovating to improve our platform and services. By embracing new technologies and creative solutions, we ensure that Konect remains a cutting-edge resource.",
    },
  ];
  return (
    <div className="guiding-principles-container">
      <div className="about-us-container">
        <div className="about-us-heading">
          Our Guiding{" "}
          <span className="heading-different-color"> Principles</span>
        </div>
        <p>Values That Anchor Our Mission and Inspire Our Community</p>
      </div>
      <div className="main-principles-container">
        {principlesData.map((data) => (
          <div className="sub-services-container">
            <div>
              <img
                className="services-icon-image"
                src={data.icon}
                alt="services-icon-image"
              />

              <div className="services-sub-heading">{data.heading}</div>
              <div className="services-des">{data.des}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Principles;
