import React from 'react'
import './TitlePath.css'


const TitlePath = ({
    exactPath,
    paths // array of objects containing path and onClick function
  }) => {
    return (
      <div className="path-container">
        {paths.map((path, index) => (
          <div key={index} className="path user_dashboard_path" onClick={path.onClick}>
            {path.label}
          </div>
        ))}
        <div className="exact-path user_dashboard_exact_path ">{exactPath}</div>
      </div>
    );
  };


export default TitlePath;
