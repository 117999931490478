import React from "react";
import "./UpdatedWith.css";
import KonectMaze from "../../assets/images/KonectMaze.svg";

const UpdatedWith = () => {
  return (
    <div className="updated-withus-container">
      <div>
        <div className="updated-with-details-container">
          <div className="updated-with-heading">
            Stay Updated with{" "}
            <span className="heading-different-color">Konect</span>
          </div>
          <p>
            Subscribe to Our Newsletter for the Latest Updates and Opportunities
          </p>
          <p>
            Join our community and be the first to know about new features,
            career tips, success stories, and exclusive opportunities. Our
            newsletter delivers valuable insights directly to your inbox.
          </p>
          {/* <div className="input-button-container"> */}
          <div className="input-container">
            <input className="email-input" placeholder="Type your email here" />
            <button
              className="submit community-button"
              onClick={() => console.log("Button clicked!")}
            >
              Subscribe
            </button>
          </div>

          {/* </div> */}
        </div>
        <div>
          <img
            className="updated-with-right-image"
            src={KonectMaze}
            alt="community-image"
          />
        </div>
      </div>
    </div>
  );
};

export default UpdatedWith;
