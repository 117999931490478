import React, { useRef, useState } from "react";
import "./SignInModal.css";
import signInImg from "../../assets/images/signInModalImage.png";
import signInOtpImg from "../../assets/images/signInModalImageOtp.png";
import appleIcon from "../../assets/images/Icons/SocialMediaIcons/apple-logo.svg";
import googleIcon from "../../assets/images/Icons/SocialMediaIcons/google-logo.svg";
import linkedinIcon from "../../assets/images/Icons/SocialMediaIcons/linkedin-logo.svg";
import { createUserAsync } from "../../services/auth/authService";
import { useDispatch } from "react-redux";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    // <div className="main-konect-modal-container">
    <div className="konect-modal-overlay" onClick={onClose}>
      <div
        className="konect-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        {/* <button className="konect-modal-close" onClick={onClose}>
            X
          </button> */}
        {children}
      </div>
    </div>
    // </div>
  );
};

const SignInModal = ({
  isOpen,
  onClose,
  isLoginModal,
  setIsLoginModal,
  isCreatePassword,
  setIsCreatePassword,
  otpVarification,
  setOtpVarification
}) => {
  // const [otpVarification, setOtpVarification] = useState(false);
  // const [isCreatePassword, setIsCreatePassword] = useState(false);
  // const [isLoginModal, setIsLoginModal] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    userRole: "",
    dateOfBirth: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignin = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
    dispatch(createUserAsync(formData));
    setOtpVarification(true);
  };

  const handleOtpVerify = (e) => {
    e.preventDefault();
    setOtpVarification(false);
    setIsCreatePassword(true);
    console.log("handleOtpVerify ");
  };

  const handleconformPassword = (e) => {
    e.preventDefault();
    setOtpVarification(false);
    setIsCreatePassword(false);
    setIsLoginModal(true);
    console.log("handleconformPassword");
  };

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if value is entered
      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };
  const handleKeyDown = (index, e) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {!otpVarification && !isCreatePassword && !isLoginModal ? (
        // Sign-in form
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img src={signInImg} className="signin-modal-img" alt="Sign In" />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Join Us Today!</h2>
              <p className="konect-modal-subtitle">
                Access referrals, career advice, and resume support.
              </p>
            </div>
            <form className="konect-form" onSubmit={handleSignin}>
              <div className="konect-form-row">
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">First Name</legend>
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="konect-input"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Last Name</legend>
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="konect-input"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
              </div>
              <div className="konect-form-row">
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Email</legend>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="konect-input"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Mobile</legend>
                    <input
                      type="tel"
                      name="mobile"
                      placeholder="Mobile"
                      className="konect-input"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
              </div>
              <div className="konect-form-row">
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">Role</legend>
                    <select
                      name="userRole"
                      className="konect-select"
                      value={formData.userRole}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Role</option>
                      <option value="referrer">Referrer</option>
                    </select>
                  </fieldset>
                </div>
                <div>
                  <fieldset className="signin-input-fieldset">
                    <legend className="signin-input-label">
                      Date of Birth
                    </legend>
                    <input
                      type="date"
                      name="dateOfBirth"
                      className="konect-input"
                      value={formData.dateOfBirth}
                      onChange={handleInputChange}
                      required
                    />
                  </fieldset>
                </div>
              </div>
              <div className="signin-button-container">
                <button type="submit" className="konect-submit">
                  Continue
                </button>
              </div>
            </form>
            <div className="konect-divider">or</div>
            <div className="konect-social-buttons">
              <button className="konect-social-button konect-google">
                <img
                  src={googleIcon}
                  alt="Google"
                  className="sign-modal-socialmedia-icon"
                />
                Google
              </button>
              <button className="konect-social-button konect-apple">
                <img
                  src={appleIcon}
                  alt="Apple"
                  className="sign-modal-socialmedia-icon"
                />
                Apple
              </button>
              <button className="konect-social-button konect-linkedin">
                <img
                  src={linkedinIcon}
                  alt="LinkedIn"
                  className="sign-modal-socialmedia-icon"
                />
                LinkedIn
              </button>
            </div>
            <p className="konect-signin-prompt">
              Already have an account?{" "}
              <a href="#" className="konect-signin-link">
                Sign in
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {otpVarification ? (
        // OTP verification form
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">OTP Verification </h2>
              <div className="konect-modal-subtitle modal-subtitle-margin">
                Enter the one-time password we've sent to your
              </div>
              <div className="otp-v-email-section">
                <span className="otp-v-email">joh****do23@gmail.com</span>
                <button className="otp-v-resend-btn">Edit</button>
              </div>
            </div>
            <form className="konect-form">
              <div className="konect-form-row">
                <div className="otp-v-input-group">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      value={digit}
                      onChange={(e) => handleChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      className={`otp-v-input ${index === 0 ? "otp-v-input-active" : ""
                        }`}
                      maxLength={1}
                    />
                  ))}
                </div>
              </div>
              <div className="signin-button-container">
                <p className="otp-v-resend-text">
                  Don't Receive OTP?{" "}
                  <button className="otp-v-resend-btn">Resend Code</button>
                </p>
                <button
                  type="submit"
                  className="konect-submit"
                  onClick={handleOtpVerify}
                >
                  Verify OTP
                </button>
                <p className="otp-v-resend-text">
                  Back to <button className="otp-v-resend-btn">Sign Up </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* create password */}
      {isCreatePassword ? (
        <div className="konect-signin-container create-password-modal">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Create Your Password </h2>
              <p className="konect-modal-subtitle">
                Access referrals, career advice, and resume support.
              </p>
            </div>
            <form
              className="konect-form-password-container"
              onSubmit={handleconformPassword}
            >
              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">Password</legend>
                <input
                  type="password"
                  placeholder="Password"
                  className="konect-input"
                // required
                />
              </fieldset>
              <div className="konect-signup-input-validation">
                Password must contain at least 12 character{" "}
              </div>

              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">
                  Confirm Password{" "}
                </legend>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="konect-input"
                // required
                />
              </fieldset>
              <div className="konect-signup-input-validation">
                Password must be identicala
              </div>

              <div className="signin-button-container">
                <button type="submit" className="konect-submit">
                  Continue
                </button>
              </div>
            </form>
            <div className="konect-divider">or</div>
            <p className="konect-signin-prompt">
              Back to Sign Up {" "}
              <a href="#" className="konect-signin-link">
                Sign in
              </a>
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {/* Login modal */}
      {isLoginModal ? (
        <div className="konect-signin-container">
          <div className="signin-modal-img-container">
            <img
              src={signInOtpImg}
              className="signin-modal-img"
              alt="Sign In"
            />
          </div>
          <div>
            <div className="signin-heading-container">
              <h2 className="konect-modal-title">Welcome Back! </h2>
              <p className="konect-modal-subtitle">
                Access referrals, career advice, and resume support.
              </p>
            </div>
            <form className="konect-form-password-container">
              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">Mail</legend>
                <input
                  type="mail"
                  placeholder="Email"
                  className="konect-input"
                // required
                />
              </fieldset>

              <fieldset className="signin-input-fieldset signin-password-input-fieldset">
                <legend className="signin-input-label">
                  Confirm Password{" "}
                </legend>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className="konect-input"
                // required
                />
              </fieldset>
              <div className="konect-signup-input-validation forgot-password">
                Forget Password?
              </div>

              <div className="signin-button-container">
                <button type="submit" className="konect-submit">
                  Continue
                </button>
              </div>
            </form>
            <div className="konect-divider">or</div>
            <div className="konect-social-buttons">
              <button className="konect-social-button konect-google">
                <img
                  src={googleIcon}
                  alt="Google"
                  className="sign-modal-socialmedia-icon"
                />
                Google
              </button>
              <button className="konect-social-button konect-apple">
                <img
                  src={appleIcon}
                  alt="Apple"
                  className="sign-modal-socialmedia-icon"
                />
                Apple
              </button>
              <button className="konect-social-button konect-linkedin">
                <img
                  src={linkedinIcon}
                  alt="LinkedIn"
                  className="sign-modal-socialmedia-icon"
                />
                LinkedIn
              </button>
            </div>
            <p className="konect-signin-prompt">
              Don’t have an account? {" "}
              <a href="#" className="konect-signin-link">
                Create Account
              </a>
            </p>
            <p className="konect-login-modal">
              {" "}
              By joining, you agree to the Konect Terms of Service and to
              occasionally receive emails from us. Please read our Privacy
              Policy to learn how we use your personal data.
            </p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

export default SignInModal;
